import React from "react"
import slugify from "slugify"

const adminTeam = [
  {
    name: "Prof. Hui Tag",
    designation:
      "Department of Botany, Rajib Gandhi University, Arunachal Pradesh",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Pallabi Kalita Hui",
    designation:
      "Assistant Professor, Department of Biotechnology, NIT Arunachal Pradesh",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Azmol Hussain Barbhuiya",
    designation: "Registrar, RIST",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Rekha Gaur",
    designation: "Advisor, All India Artist Association",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Dibyajyoti Gharphalia",
    designation:
      "MD, Associate Professor, Department of OBG, Guwahati Medical College & Hospital",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. J. K. Kalita",
    designation: "MS, FAMS, General and Laparoscopic Surgeon, Guwahati",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Bikash Kumar Das",
    designation: "MD, Department of Medicine, Nemcare Hospital, Guwahati",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Dr. Sandipan Sarma",
    designation: "MD, Consultant",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Rahul Das",
    designation: "B.Tech, Engineer, Power Grid Corporation of India Ltd.",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Tulika Das",
    designation: "BE, Senior Engineer, Civil, Oil India Ltd.",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Mridul Deka",
    designation: "Assistant Professor, Department of ME, JIST",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Nitul Kr. Das",
    designation: "BE, M.Tech, Assistant Manager, APDCL",
    avatar: "/images/people/default_avatar.jpg",
  },
]

const AdvisoryCommittee = ({ title = "Advisory Committee" }) => {
  return (
    <>
      <section className="team-area-three ptb-100">
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
            <p>
              Honourable Members of the Advisory Committee of Darwin Academy,
              Mirza.
            </p>
          </div>
          <div className="row justify-content-md-center">
            {adminTeam.map(t => (
              <div
                key={slugify(t.name)}
                className="col-lg-3 col-md-4 col-sm-6 col-12"
              >
                <div className="single-team-member">
                  <img src={t.avatar} alt="Image" />
                  <div
                    className="team-content"
                    style={{ width: "100%", padding: 0 }}
                  >
                    <div
                      className="team-name"
                      style={{ width: "100%", padding: "15px 10px" }}
                    >
                      <h4 style={{ fontSize: 15 }}>{t.name}</h4>
                      <span style={{ fontSize: 11, minHeight: 40 }}>
                        {t.designation}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default AdvisoryCommittee
