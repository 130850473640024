import React from "react"
import AdministrativeTeam from "../components/about/AdmistrativeTeam"
import AdvisoryCommittee from "../components/about/AdvisoryCommittee"
import PageHeadBanner from "../components/PageHeadBanner"
import ScholarshipBanner from "../components/ScholarshipBanner"
import Seo from "../components/Seo"
import withLayout from "../layout/withLayout"

const AboutUs = withLayout(({ siteData }) => {
  return (
    <>
      <Seo title={"About Us"} siteMetadata={siteData} />
      <PageHeadBanner title={"About Us"} subtitle={"A few words about us"} />
      <section className="about-area pt-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pt-5">
              <div className="about-content ml-15">
                <span>About our College</span>
                <h3>Our education system inspires the next generation</h3>
                <p style={{ textAlign: "justify" }}>
                  With the relentless effort of a few diligent and dedicated
                  persons an institute namely “Darwin Academy” was born on 21st
                  july, 2021 at the heart of mirza . which can be regarded as
                  the “Educational Hub” of South Kamrup Established after the
                  great name of a legend of Science, Charles Darwin, “Darwin
                  Academy” bears significance. It was actually a cherished dream
                  to set up such an institute and promote it to that height so
                  that the student can equip themselves with knowledge and skill
                  in the true sense of the term.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 pt-5">
              <img
                src="/images/education-image-1.jpg"
                style={{ height: "100%", width: "100%", objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </section>

      <section
        className="our-campus-information-area"
        style={{ padding: "50px 0 0 0" }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6">
              <div className="campus-img">
                <img src="/images/education-image-2.jpg" alt="Image" />
              </div>
            </div>
            <div className="col-xl-6">
              <div className="campus-content">
                {/* <span>Our campus information</span> */}
                <h3 style={{ color: "#fff" }}>Our Mission and Vision</h3>
                <p style={{ textAlign: "justify" }}>
                  The mission of the college is to encourage the students to
                  learn and discover so that they can create . The legendary
                  scientist Darwin said “Survival of the fittest” and based on
                  this concept our mission is to make everyone competent and
                  capable of realising incoming opportunities in the years to
                  come, enriching individual lives of students and becoming
                  dedicated leaders to serve the society.
                </p>
                <p style={{ textAlign: "justify" }}>
                  Our Vision is to provide quality equation by experienced
                  teachers to the students at an affordable fees and to enhance
                  the personality and skill of the students in its true sense.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="about-area pb-100">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-md-6 pt-5">
              <div className="about-content ml-15">
                <span>About the Emblem</span>
                <h3>Emblem of the Academy</h3>
                <p style={{ textAlign: "justify" }}>
                  The logo is an infographic of a human brain situated at the
                  centre, over a symbolic representation of an open book which
                  encircled together by organization title and a growing vine
                  from an earth lamp connected with a pen. The symbolic
                  representation means education with improvement of knowledge,
                  skill and upgrowth of intelligence for survival of the fittest
                  as coined by Charles Darwin.
                </p>
              </div>
            </div>

            <div className="col-12 col-md-6 pt-5 d-flex justify-content-center">
              <img
                src="/images/logo.svg"
                style={{ width: 280, objectFit: "cover" }}
              />
            </div>
          </div>
        </div>
      </section>

      <AdministrativeTeam />

      <ScholarshipBanner />

      <AdvisoryCommittee />
    </>
  )
})

export default AboutUs
