import React from "react"
const ScholarshipBanner = () => {
  return (
    <section className="application-submit-area">
      <div className="container-fluid" style={{ margin: 0, padding: 0 }}>
        <div className="application-submit-bg">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="application-submit-content">
                <h2>
                  Scholarship Availabe for Meritorious and Economically Weaker
                  Students.
                </h2>
                <a href="https://sites.google.com/darwinacademymirza.com/inquiry/" className="default-btn">
                  Inquiry Form
                  <i className="ri-arrow-right-line"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default ScholarshipBanner
