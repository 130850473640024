import React from "react"
import slugify from "slugify"

const adminTeam = [
  {
    name: "Dr. Ramesh Ch. Kalita",
    designation: "Principal",
    qualification: "MSc, MPhil, PhD",
    avatar: "/images/people/rc_kalita_1.jpg",
  },
  {
    name: "Dr. Prabin Ch. Kalita",
    designation: "Course Coordinator",
    qualification: "MSc, PhD",
    avatar: "/images/people/pc_kalita_1.jpg",
  },
  {
    name: "Dr. Sailendra Kr. Das",
    designation: "Controller of Examinations",
    qualification: "MA, PhD",
    avatar: "/images/people/default_avatar.jpg",
  },
  {
    name: "Mrs Dolly Das",
    designation: "Coordinator, Student Welfare",
    qualification: "MA, BEd",
    avatar: "/images/people/default_avatar.jpg",
  },
]

const AdministrativeTeam = ({ title = "Admistrative Team" }) => {
  return (
    <>
      <section
        className="team-area-three ptb-100"
        style={{ backgroundColor: "#e8e8e8" }}
      >
        <div className="container">
          <div className="section-title">
            <h2>{title}</h2>
            <p>
              Experianced and renowned members at administration of Darwin
              Academy, Mirza.
            </p>
          </div>
          <div className="row justify-content-md-center">
            {adminTeam.map(t => (
              <div key={slugify(t.name)} className="col-lg-3 col-md-6">
                <div className="single-team-member">
                  <img src={t.avatar} alt="Image" />
                  <div
                    className="team-content"
                    style={{ width: "100%", padding: 0 }}
                  >
                    <div
                      className="team-name"
                      style={{ width: "100%", padding: "15px 10px" }}
                    >
                      <h4 style={{ fontSize: 15 }}>{t.name}</h4>
                      <span>({t.qualification})</span>
                      <span>{t.designation}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </>
  )
}

export default AdministrativeTeam
